<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>兑换管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input v-model="searchForm.question" placeholder="问题名称"></el-input>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="handleQuery"></el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd">添加</el-button>
        <el-button type="danger" :plain="true" icon="el-icon-delete" :disabled="multiple"
          @click="handleDelete">批量删除</el-button>
        <!-- <el-button type="warning" plain icon="el-icon-download" @click="handleExport">导出</el-button> -->
      </div>
    </div>

    <div class="tbale_box">
      <el-table v-loading="loading" :data="questionList" border @selection-change="handleSelectionChange"
        style="width: 100%" height="100%">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="id" width="50" align="center" prop="id" />
        <el-table-column label="常见问题" align="center" prop="question" />
        <el-table-column label="回答" align="center" prop="answer" />
        <el-table-column label="操作" width="180" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!-- <pagination v-show="total > 0" :total="total" :page.sync="searchForm.pageNum" :limit.sync="searchForm.pageSize"
        @pagination="getList" /> -->

    <!-- 添加或修改常见问题汇总对话框 -->
    <el-dialog :visible.sync="open" width="30%" append-to-body class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ title }}
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="常见问题" prop="question">
          <el-input v-model="form.question" placeholder="请输入常见问题" />
        </el-form-item>
        <el-form-item label="回答" prop="answer">
          <el-input v-model="form.answer" placeholder="请输入回答" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import api from '@/api/index'

export default {
  name: "Question",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 常见问题汇总表格数据
      questionList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      pageBox: {
        pageNum: 1,
        pageSize: 10
      },
      searchForm: {
        question: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getList()
    },
    /** 查询常见问题汇总列表 */
    getList() {
      this.loading = true;
      api.listQuestion(this.pageBox, this.searchForm).then(response => {
        this.questionList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    resetForm() { },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        question: null,
        answer: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.pageBox.pageNum = 1;
      this.getList();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加常见问题汇总";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      api.getQuestion(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改常见问题汇总";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            api.updateQuestion(this.form).then(response => {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.open = false;
              this.getList();
            });
          } else {
            api.addQuestion(this.form).then(response => {
              this.$message({
                message: '新增成功',
                type: 'success'
              })
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.delQuestion(ids).then(res => {
          this.getList();
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        }).catch(() => { });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('frequentlyQuestion/question/export', {
        ...this.searchForm
      }, `question_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
